import * as React from 'react';
import { Activity, ActivityHistory, FreeTextActivity } from '@Types/Activity';
import { sanitizeString } from '@Utils/string.utils';
import styled from 'styled-components';
import { useMemo } from 'react';
import { sortBy } from 'lodash';

interface Props {
    answer: ActivityHistory;
    activity: Activity & FreeTextActivity;
}
export const DisplayAnswer: React.FC<Props> = (props) => {
    const getIndexesOfText = (text: string, word: string) => {
        const sanitizedText = sanitizeString(text)
            .toLowerCase()
            .replaceAll(/[\u0300-\u036f]/g, '');
        const sanitizedWord = sanitizeString(word)
            .toLowerCase()
            .replaceAll(/[\u0300-\u036f]/g, '');

        console.table([sanitizedText, sanitizedWord]);

        const regex = new RegExp(`\\b${sanitizedWord}\\b`, 'gi');
        let res;

        const indexes = [];
        while ((res = regex.exec(sanitizedText)) != null) {
            indexes.push(res.index);
        }

        console.log(indexes);

        return indexes;
    };

    const indexMatch = useMemo(
        () =>
            sortBy(
                props.activity.keywords
                    .map((keyword, index) => {
                        const orKeywords = keyword.split('$');

                        if (orKeywords.length === 1) {
                            const indexesOfMatchingRes = getIndexesOfText(props.answer.answer ?? '', keyword);
                            return indexesOfMatchingRes.map((i) => [i, keyword.length] as const);
                        } else {
                            return orKeywords
                                .map((orKeyword) => {
                                    const indexesOfMatchingRes = getIndexesOfText(props.answer.answer ?? '', orKeyword);
                                    return indexesOfMatchingRes.map((i) => [i, orKeyword.length] as const);
                                })
                                .flat();
                        }
                    })
                    .flat()
                    .filter((v) => v !== null),
                ([index]) => index
            ),
        [props.activity.keywords, props.answer.answer]
    );

    const highlightedAnswer = useMemo(() => {
        const components = [];

        let lastIndex = 0;

        for (const [index, length] of indexMatch) {
            const beforeIndex = props.answer.answer?.slice(lastIndex, index) ?? '';
            components.push(beforeIndex);
            components.push(<Mark valid>{props.answer.answer?.slice(index, index + length)}</Mark>);
            lastIndex = index + length;
        }

        components.push(props.answer.answer?.slice(lastIndex));

        return components;
    }, [indexMatch, props.answer.answer]);

    return (
        <div className="flex flex-column py-2">
            <div className="he-paragraph--medium gray-900 mb-2">Votre réponse</div>
            <TextContainer>
                {highlightedAnswer.map((component, index) => (
                    <React.Fragment key={index}>{component}</React.Fragment>
                ))}
            </TextContainer>
            <div className="he-paragraph--medium gray-900 mt-3 mb-3">Mots clés attendus</div>
            <div className="flex flex-column gap-2">
                {props.activity.keywords.map((keyword, index) => {
                    const sanitizedAnswer = sanitizeString(props.answer.answer ?? '').toLowerCase();
                    const sanitizedOrKeywords = keyword.split('$');

                    if (sanitizedOrKeywords.length === 1) {
                        const keyword = sanitizeString(sanitizedOrKeywords[0]).toLocaleLowerCase();
                        return (
                            <span>
                                &bull;&nbsp;
                                <Mark valid={sanitizedAnswer.includes(keyword)} className="w-max" key={index}>
                                    {sanitizedOrKeywords[0]}
                                </Mark>
                            </span>
                        );
                    }
                    const components = [];
                    for (const orKey of sanitizedOrKeywords) {
                        const indexes = getIndexesOfText(sanitizedAnswer, sanitizeString(orKey).toLowerCase());

                        components.push(
                            <Mark valid={indexes.length >= 1} className="w-max" key={index}>
                                {orKey}
                            </Mark>
                        );
                    }

                    return (
                        <span>
                            &bull;&nbsp;
                            {components.map((c, index) => {
                                return (
                                    <>
                                        {c}
                                        {index !== components.length - 1 && (
                                            <span className="mx-2 he-paragraph--regular text-gray-700"> ou </span>
                                        )}
                                    </>
                                );
                            })}
                        </span>
                    );
                })}
            </div>
        </div>
    );
};

const TextContainer = styled.div`
    line-height: 1.6;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
`;

const Mark = styled.div<{
    valid?: boolean;
}>`
    background-color: ${(props) => (props.valid ? '#e6f4f1' : '#f4e6e8')};
    border-radius: 4px;
    padding: 2px 4px;
    color: ${(props) => (props.valid ? '#00a86b' : '#e0216a')};
    display: inline;
`;
